<template>
<div>
  <div>
    <div class="md:flex justify-between my-5 items-center"> 

      <button class="h-10 border-0 px-5 rounded" @click="changeFilter(''), changeDepartment('')">همه</button>
      <button class="h-10  px-5 rounded bg-success" @click="changeFilter(1)">جدید</button>
      <button class="h-10  px-5 rounded bg-success" @click="changeFilter(2)">در انتظار پاسخ</button>
      <button class="h-10  px-5 rounded bg-success" @click="changeFilter(3)">پاسخ مشتری</button>
      <button class="h-10  px-5 rounded" @click="changeFilter(4)">پاسخ کارشناس</button>
      <button class="h-10  px-5 rounded bg-warning" @click="changeFilter(5)">در دست بررسی</button>
      <button class="h-10  px-5 rounded bg-warning" @click="changeFilter(6)">در دست اقدام</button>
      <button class="h-10  px-5 rounded" @click="changeFilter(7)">بسته</button>
      </div>
       <div v-if="is_Financial" class="flex justify-between">
        <div>

          <button class="h-10 mx-2 text-white border-0 px-5 rounded bg-secondary" @click="changeDepartment(1)">پشتیبانی فنی</button>
          <button class="h-10 mx-2 text-white border-0 px-5 rounded bg-secondary" @click="changeDepartment(2)"> فروش و بازاریابی</button>
          <button class="h-10 mx-2 text-white border-0 px-5 rounded bg-secondary" @click="changeDepartment(3)"> مالی و اداری</button>
        </div>
        <div class="mb-3 flex">
              <vs-input
                placeholder="search"
                @keyup.enter="
                  search.slice(0, 3) === '###'
                    ? imageManagement()
                    : getAllTicket()
                "
                v-model="search"
              ></vs-input>
              <vs-button size="large" icon="search" @click="getAllTicket">
              </vs-button>
            </div>
       </div>
    
    <vs-pagination :total="countPage" v-model="pageNumber" ></vs-pagination>
    <div class="grid grid-cols-1">
      <div class=" bg-primaryLight py-5 px-3 my-1 rounded text-left cursor-pointer "  :key="ticket" v-for="ticket in tickets" @click="doubleSelection(ticket.id)" v-if="(ticketFilter === '' && ticketDepartment === '')">
        <div class="md:flex justify-between">
          <span class="text-lg w-1/12 ">
            {{ ticket.id }}
          </span>
          <div class="text-white w-1/5 ">
            {{ ticket.user_fullname }}
          </div>
          
          <span class="block w-full md:w-1/3">
          {{ ticket.subject }}
          </span>
          <span class="block w-full md:w-1/6 md:text-center">
            {{ ticket.category_name }}
          </span>
          <span class="block w-full md:w-1/6">
            {{ ticket.created_at }}
          </span>
          <span class="block w-full md:w-1/6 text-center" >
            {{ ticket.agent_fullname }}
          </span>
          <span v-if="(ticket.status_id === 1)" class="text-primary text-sm block w-full md:w-32 p-2 rounded text-center md:ml-5 bg-success" style="border-left: 1px solid #fff;">
            {{ ticket.status_name }}
          </span>
          <span v-if="(ticket.status_id === 2)" class="text-primary text-sm block w-full md:w-32 p-2 rounded text-center md:ml-5 bg-success" style="border-left: 1px solid #fff;">
            {{ ticket.status_name }}
          </span>
          <span v-if="(ticket.status_id === 3)" class="text-primary text-sm block w-full md:w-32 p-2 rounded text-center md:ml-5 bg-success" style="border-left: 1px solid #fff;">
            {{ ticket.status_name }}
          </span>
          <span v-if="(ticket.status_id === 4)" class="text-gray text-sm block w-full md:w-32 p-2 rounded text-center md:ml-5 bg-primary" style="border-left: 1px solid #fff;">
            {{ ticket.status_name }}
          </span>
          <span v-if="(ticket.status_id === 5)" class="text-primary text-sm block w-full md:w-32 p-2 rounded text-center md:ml-5 bg-warning" style="border-left: 1px solid #fff;">
            {{ ticket.status_name }}
          </span>
          <span v-if="(ticket.status_id === 6)" class="text-primary text-sm block w-full md:w-32 p-2 rounded text-center md:ml-5 bg-warning" style="border-left: 1px solid #fff;">
            {{ ticket.status_name }}
          </span>
          <span v-if="(ticket.status_id === 7)" class="text-gray text-sm block w-full md:w-32 p-2 rounded text-center md:ml-5 bg-primary" style="border-left: 1px solid #fff;">
            {{ ticket.status_name }}
          </span>
        </div>

      </div>
    
      <div class=" bg-primaryLight py-5 px-3 my-1 rounded text-left cursor-pointer "  :key="filteredTicket" v-for="filteredTicket in tickets" @click="doubleSelection(filteredTicket.id)" v-if="(filteredTicket.category_id === ticketDepartment || filteredTicket.status_id === ticketFilter)">
        <div class="md:flex justify-between"  >
          <span class="text-lg w-1/12 ">
            {{ filteredTicket.id }}
          </span>
          <div class="text-white w-1/5 ">
            {{ filteredTicket.user_fullname }}
          </div>
          
          <span class="w-1/3">
          {{ filteredTicket.subject }}
        </span>
        <span class="w-1/6 text-center">
            {{ filteredTicket.category_name }}
          </span>
          <span class="w-1/6">
            {{ filteredTicket.created_at }}
          </span>
          <span class="w-1/6 text-center" >
            {{ filteredTicket.agent_fullname }}
          </span>
          <span v-if="(filteredTicket.status_id === 1)" class="text-success text-sm w-32 p-2 rounded text-center ml-5 bg-primary" style="border-left: 1px solid #fff;">
            {{ filteredTicket.status_name }}
          </span>
          <span v-if="(filteredTicket.status_id === 2)" class="text-success text-sm w-32 p-2 rounded text-center ml-5 bg-primary" style="border-left: 1px solid #fff;">
            {{ filteredTicket.status_name }}
          </span>
          <span v-if="(filteredTicket.status_id === 3)" class="text-success text-sm w-32 p-2 rounded text-center ml-5 bg-primary" style="border-left: 1px solid #fff;">
            {{ filteredTicket.status_name }}
          </span>
          <span v-if="(filteredTicket.status_id === 4)" class="text-gray text-sm w-32 p-2 rounded text-center ml-5 bg-primary" style="border-left: 1px solid #fff;">
            {{ filteredTicket.status_name }}
          </span>
          <span v-if="(filteredTicket.status_id === 5)" class="text-warning text-sm w-32 p-2 rounded text-center ml-5 bg-primary" style="border-left: 1px solid #fff;">
            {{ filteredTicket.status_name }}
          </span>
          <span v-if="(filteredTicket.status_id === 6)" class="text-warning text-sm w-32 p-2 rounded text-center ml-5 bg-primary" style="border-left: 1px solid #fff;">
            {{ filteredTicket.status_name }}
          </span>
          <span v-if="(filteredTicket.status_id === 7)" class="text-gray text-sm w-32 p-2 rounded text-center ml-5 bg-primary" style="border-left: 1px solid #fff;">
            {{ filteredTicket.status_name }}
          </span>
        </div>

      </div>


    </div>
  </div>


  <vs-pagination :total="countPage" v-model="pageNumber" ></vs-pagination>

</div>

</template>


<script>

  export default {

    setup() {
  
      return {
      
      };
    },
    data: () => ({
      countPage: 0,
      pageNumber: 1,
      selectedTicket: [],
      tickets: [],
      ticketColor: "",
      ticketFilter:'',
      ticketDepartment:'',
      search: "",
      techTickets:[],
      saleTickets: [],
   
    }),
    watch: {
      pageNumber() {
        this.getAllTicket();
      },
      selectedTicket(value) {
        // console.log(value);
        this.doubleSelection(value);
      },
    },
    computed: {
      is_Financial() {
        if (
        this.$store.state.AppActiveUser.email ===
          "sajadrasti@haiocloud.com" || "h.safarzadeh@haio.ir" || "m.hooshmand@haiocloud.com"
      ) {
        return true;
      } else {
        return false;
      }
    }
    },
    methods: {
      doubleSelection(id) {
        this.$router.push(`/ticket/${id}`);
      },
      getAllTicket() {
        this.$http
          .get(
            `/admin/ticket/index?page=${this.pageNumber}&search=${this.search}&label_id=1`
          )
          .then((res) => {
            const p = res.data.params;
            this.countPage = p.last_page;
            this.tickets = p.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      changeDepartment(filter) {
        this.ticketDepartment = filter;
    
      },
      changeFilter(filtered) {
        this.ticketFilter = filtered;
    
      }
    },
    created() {
      this.getAllTicket();
       // eslint-disable-next-line no-unused-vars
       const interval = window.setInterval(this.getAllTicket, 25000);
      // this.$router.push('/dashboard')

    }, 

  }
</script>